
import styled from 'styled-components'

const Styling = styled.div`
width: 100%;
color:var(--white);
height:100px;
background:var(--black);
padding:0 4rem;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
align-items: center;
z-index: 100;
position: relative;

    .inner{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    }

    .title{
    text-transform:uppercase;
    margin-bottom: 1rem;
    }

    .percent_complete{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    }

    .percent_bar{
    width: 100%;
    height:4px;
    background: var(--grey);
    border-radius: 2px;

        .percent_indicator{
        width:0%;
        height: 100%;
        background: var(--white);
        }

    }

`

export { Styling }