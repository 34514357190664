import React from 'react'
import Download from '../../../../images/download.inline.svg'

export default function DownloadableContent({downloadableContent}) {
    return (
        <div className="downloadableContent">
            {
                downloadableContent?.map((lesson, index) => {
                    
                    return(
                    <a href={lesson?.lessonFile?.localFile?.publicURL} download className={`downloadableContentItem downloadableContentItem-${index}`}>
                        <div className="lesson_title">{lesson.fileTitle}</div>
                        <div className="download" >
                            <Download className="downloadIcon"/> Download
                        </div>
                    </a>
                    )
                })
            }        
        </div>
    )
}
