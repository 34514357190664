import React, {useState, useEffect} from 'react'
import CurriculumLesson from './CurriculumLesson'
import Chevron from '../../../../images/chevron.inline.svg'
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
  } from 'react-accessible-accordion';

export default function CurriculumChapter({
    chapter, 
    index, 
    completed,
    setCurrentLesson, 
    currentLesson,
    setCurrentChapterIndex,
    setCurrentLessonIndex,
    courseProgress
}) {

    const chapterIndex = index;
    const [lessonsComplete, setLessonComplete] = useState('X');
    

    useEffect(() => {
        setLessonComplete(
            courseProgress[chapterIndex]?.lessonsComplete !== undefined 
            ? courseProgress[chapterIndex]?.lessonsComplete 
            : "X"
        );
    }, [courseProgress])
    
    return (
        <div>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton className={`chapter chapter-${index}`}>
                        <div className="number">{index+1})</div>
                        <div className="chapter_title">{chapter.chapterTitle}</div>
                        <div className="chapter-right">
                            <div className="progress">{lessonsComplete}/{chapter.lesson.length}</div>
                            <Chevron className="chevron"/>
                        </div>
                    </AccordionItemButton>
                </AccordionItemHeading>
                
                    <ul>
                        {chapter.lesson.map((lesson, index) => {
                            
                            return(
                                <CurriculumLesson 
                                lesson={lesson} 
                                index={index} 
                                completed={completed}
                                chapter={chapter.chapterTitle}
                                chapterIndex={chapterIndex}
                                currentLesson={currentLesson} 
                                setCurrentLesson={setCurrentLesson} 
                                setCurrentChapterIndex={setCurrentChapterIndex}
                                setCurrentLessonIndex={setCurrentLessonIndex}
                                courseProgress={courseProgress}
                                isComplete={false}
                                />
                            )
                        })}
                    </ul>
                </AccordionItem>
        </div>
    )
}
