import React, { useEffect } from "react";
import {
  expandAccordionItemById,
  closeAccordionItemsById,
} from "../../../../utils/openCloseAccordion";
import Vimeo from "@u-wave/react-vimeo";

export default function Player({
  video,
  courseID,
  lessonTitle,
  updateCurrentCourseProgress,
  user,
  setCurrentLessonIndex,
  setCurrentChapterIndex,
  curriculum,
  currentChapterIndex,
  currentLessonIndex,
}) {
  const loadNextVideo = () => {
    if (
      curriculum.chapter[currentChapterIndex].lesson.length - 1 ===
        currentLessonIndex &&
      currentChapterIndex !== curriculum.chapter.length - 1
    ) {
      //console.log('last video in chapter')
      closeAccordionItemsById(currentChapterIndex);
      expandAccordionItemById(currentChapterIndex + 1);
      setCurrentChapterIndex(currentChapterIndex + 1);
      setCurrentLessonIndex(0);
    } else if (
      curriculum.chapter[currentChapterIndex].lesson.length - 1 ===
        currentLessonIndex &&
      currentChapterIndex === curriculum.chapter.length - 1
    ) {
      //console.log('last video in last chapter')
    } else {
      //console.log('there is a following video')
      setCurrentLessonIndex(currentLessonIndex + 1);
    }
  };

  const rawVideo = video.replace("https://vimeo.com/", "");
  const splitRawVid = rawVideo.split("/");
  const videoHash = `https://player.vimeo.com/video/${splitRawVid[0]}?h=${splitRawVid[1]}`;
  //console.log(video)
  //console.log(videoHash)

  async function lessonCompleted(userID, courseID, lessonTitle) {
    const jwt = window.localStorage.userJwt;
    const rawResponse = await fetch(
      `${process.env.GATSBY_CMS_URL}/wp-json/toolroom/v1/user/${userID}/lesson-completed?jwt=${jwt}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ course: courseID, lesson: lessonTitle }),
      }
    );

    const content = await rawResponse.json();
    updateCurrentCourseProgress(user);
  }

  return (
    <div className="player">
      {/* <iframe src={`${videoHash}`} width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen referrerpolicy="strict-origin-when-cross-origin"></iframe> */}
      <Vimeo
        video={video}
        key={video}
        onEnd={() => {
          lessonCompleted(user, courseID, lessonTitle);
          loadNextVideo();
        }}
      />
    </div>
  );
}
