import React, {useEffect} from 'react'
import { Styling } from './styled.js'
import gsap from 'gsap'

export default function Progress({progress}) {

    const updateIndicator = (progress) => {
        gsap.to('.percent_indicator', {width:progress+'%'})
    }

    useEffect(() => {
        updateIndicator(progress)
    }, [progress])

    return (
        <Styling  className="progress"> 
            <div className="inner">
            <div className="title">Your progress</div>
            <div className="percent_complete">{progress}% complete</div>
            <div className="percent_bar">
                <div className="percent_indicator"></div>
            </div>
            </div>
        </Styling>
    )
}
