
import styled from 'styled-components'

const Styling = styled.div`
display:grid;
grid-template-columns:100px auto;
grid-gap:2rem;
padding-top:4rem;
border-top:1px solid rgba(0,0,0,.2);

    .profile{
    width:100px;
    height:100px;
    border-radius:100%;
    overflow:hidden;
    }

    .name{
    width:100%;
    margin-bottom:2rem;
    font-family: 'tomato--bold';
    }

    .bio{
    font-size:1.3rem;
    line-height:2rem;
    max-width:700px;
    }
`

export { Styling }