import React, { useState, useEffect, useRef } from "react";
import { Styling } from "../styles/pages/course-player.styled";
import { graphql } from "gatsby";
import CoursePlayerHeader from "../components/page-components/CoursesPlayer/Header";
import Player from "../components/page-components/CoursesPlayer/Player";
import Progress from "../components/page-components/CoursesPlayer/Progress";
import Curriculum from "../components/page-components/CoursesPlayer/Curriculum";
import Info from "../components/page-components/CoursesPlayer/Info";
// import getCurrentCourseProgress from '../utils/getCurrentCourseProgress'
import { isLoggedIn } from "../utils/auth";
import Seo from "../components/global/seo";

export default function CoursePlayer({ data }) {
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [courseProgress, setCourseProgress] = useState([]);
  const [courseProgressPercent, setCourseProgressPercent] = useState();
  const curriculum = data.wpCourse.courseBuilder.curriculum[0];
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [enrollmentStatus, setEnrollmentStatus] = useState(false);
  const currentLesson =
    curriculum.chapter[currentChapterIndex].lesson[currentLessonIndex];
  const header = useRef();
  const contentWrap = useRef();
  const curriculumRef = useRef();

  //console.log(data.wpCourse.courseBuilder)

  const databaseId = data.wpCourse.databaseId;

  async function getCurrentCourseProgress(userID) {
    const jwt = window.localStorage.userJwt;
    const url = `${process.env.GATSBY_CMS_URL}/wp-json/toolroom/v1/user/${userID}/courses-completed?jwt=${jwt}`;
    const response = await fetch(url);
    const returnedCourses = await response.json();
    const subscribedCourses = [];
    let currentCourseProgress = [];

    // ============================================================
    // Loop over all returned courses
    // ============================================================
    Object.entries(returnedCourses.data).forEach((entry) => {
      const courseId = entry;

      // ============================================================
      // Find THIS course by matching ID's
      // ============================================================
      if (parseInt(courseId) === databaseId) {
        // ============================================================
        // Loop over all object rows and remap multi-array to
        // ============================================================

        Object.entries(entry[1]).forEach((chapter, index) => {
          let lessonsArray = [];
          let chapterTitle = chapter[0];
          let lessonsCompleteCount = 0;
          let totalLessonsCount = 0;

          Object.entries(chapter[1]).forEach((lessons, index) => {
            lessonsArray.push({
              lessonTitle: lessons[0],
              lessonComplete: lessons[1],
            });

            if (lessons[1] === true) {
              lessonsCompleteCount = lessonsCompleteCount + 1;
            }

            totalLessonsCount = totalLessonsCount + 1;
          });
          currentCourseProgress[index] = {
            chapterTitle: chapterTitle,
            lessons: lessonsArray,
            lessonsComplete: lessonsCompleteCount,
            totalLessons: totalLessonsCount,
          };
        });
      }
    });

    const calculatePercent = (currentCourseProgress) => {
      let total = 0,
        complete = 0;

      currentCourseProgress.forEach((progress) => {
        complete = complete + parseInt(progress.lessonsComplete);
        total = total + parseInt(progress.totalLessons);
      });

      const percent = Math.floor((complete / total) * 100);
      setCourseProgressPercent(percent);
    };

    await setCourseProgress(currentCourseProgress);
    await calculatePercent(currentCourseProgress);
  }

  async function isEnrolled(userID, courseID, jwt) {
    const url = `${process.env.GATSBY_CMS_URL}/wp-json/toolroom/v1/user/${userID}/is-enrolled/${courseID}?jwt=${jwt}`;
    const response = await fetch(url);
    const isEnrolled = await response.json();
    await setEnrollmentStatus(isEnrolled.data);
    // await //console.log(isEnrolled.data)

    if (isEnrolled.data === false || response.status === 404) {
      setLoadingMessage("You are not enrolled on this course.");
    }
  }

  const calculatePlayerHeight = () => {
    let windowHeight;
    let curriculum = curriculumRef.current.querySelector(".curriculum");
    if (typeof window !== "undefined") {
      windowHeight = window.innerHeight;
    }
    let headerHeight = header.current.querySelector("header").clientHeight;
    let bottomHeight = windowHeight - headerHeight - 100;
    contentWrap.current.style.marginTop = headerHeight + "px";
    curriculum.style.height = bottomHeight + "px";
  };

  // const downloadableContent = () => {
  //   let downloads = []

  //   data.wpCourse.courseBuilder.curriculum.forEach(chapter => {
  //     chapter.chapter.forEach(lesson => {
  //         lesson.lesson.filter(lesson => lesson.lessonFile !== null ? downloads.push(lesson) : '')
  //     })
  //   })
  //   setDownloadContent(downloads)
  //   return downloads
  // }

  useEffect(() => {
    setIsLoggedInUser(isLoggedIn());
  }, []);

  useEffect(() => {
    if (isLoggedInUser === true && typeof window !== "undefined") {
      const user = JSON.parse(window.localStorage.gatsbyUser);
      const jwt = window.localStorage.userJwt;
      isEnrolled(user.userID, databaseId, jwt);
      getCurrentCourseProgress(user.userID);
      setCurrentUser(user.userID);
      calculatePlayerHeight();
      window.addEventListener("resize", calculatePlayerHeight);

      return () => {
        window.removeEventListener("resize", calculatePlayerHeight);
      };
    }
  }, [isLoggedInUser]);

  return (
    <Styling>
      <Seo
        title={data.wpCourse.seo.title}
        description={data.wpCourse.seo.metaDesc}
      />
      {isLoggedInUser ? (
        <>
          <div className="header" ref={header}>
            <CoursePlayerHeader />
          </div>

          <div className="player_upper" ref={contentWrap}>
            <div className="left">
              {enrollmentStatus === true ? (
                <>
                  <Player
                    video={currentLesson.lessonVideoUrl}
                    courseID={databaseId}
                    lessonTitle={currentLesson.lessonTitle}
                    updateCurrentCourseProgress={getCurrentCourseProgress}
                    setCurrentLessonIndex={setCurrentLessonIndex}
                    setCurrentChapterIndex={setCurrentChapterIndex}
                    user={currentUser}
                    curriculum={curriculum}
                    currentChapterIndex={currentChapterIndex}
                    currentLessonIndex={currentLessonIndex}
                  />
                  <Info
                    courseTitle={data.wpCourse.title}
                    instructors={data.wpCourse.courseBuilder.instructors}
                    currentLesson={currentLesson}
                  />
                </>
              ) : (
                <div id="loading">{loadingMessage}</div>
              )}
            </div>
            <div className="right" ref={curriculumRef}>
              <Curriculum
                enrollmentStatus={enrollmentStatus}
                setCurrentChapterIndex={setCurrentChapterIndex}
                setCurrentLessonIndex={setCurrentLessonIndex}
                setCurrentLesson={"test"}
                currentLesson={currentLesson}
                curriculum={curriculum}
                downloadCount={
                  data.wpCourse.courseBuilder.downloads !== null
                    ? data.wpCourse.courseBuilder.downloads.length
                    : 0
                }
                downloadableContent={data.wpCourse.courseBuilder.downloads}
                courseProgress={courseProgress}
              />
              <Progress progress={courseProgressPercent} />
            </div>
          </div>
        </>
      ) : (
        <div className="course_dashboard--not-loggedin">
          You must login to view your courses
        </div>
      )}
    </Styling>
  );
}

export const query = graphql`
  query ($slug: String!) {
    wpCourse(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
      }
      id
      title
      databaseId
      courseBuilder {
        downloads {
          lessonFile {
            id
            localFile {
              publicURL
            }
          }
          fileTitle
        }
        instructors {
          instructor {
            ... on WpInstructor {
              title
              instructor {
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 1500
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                biography
              }
            }
          }
        }
        curriculum {
          chapter {
            lesson {
              lessonTitle
              lessonVideoUrl
              lessonVideoDuration
            }
            chapterTitle
          }
        }
      }
    }
  }
`;
