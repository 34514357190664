const expandAccordionItemById = (index) => {
    const accordion__items = Array.from(document.querySelectorAll('.accordion__item'));
    const accordion__children = Array.from(accordion__items[index].querySelectorAll('.lesson'))
    accordion__items[index].querySelector('.chapter').setAttribute('aria-expanded', 'true')
    accordion__children.map(child => child.removeAttribute('hidden'))
}
const closeAccordionItemsById = (index) => {
    const accordion__items = Array.from(document.querySelectorAll('.accordion__item'));
    const accordion__children = Array.from(accordion__items[index].querySelectorAll('.lesson'))
    accordion__items[index].querySelector('.chapter').setAttribute('aria-expanded', 'false')
    accordion__children.map(child => child.setAttribute('hidden',""))
}
const closeAllAccordionItems = () => {
    // const accordion__items = Array.from(document.querySelectorAll('.accordion__item'));
    // const accordion__children = Array.from(accordion__items.querySelectorAll('.lesson'))
    // accordion__items.querySelector('.chapter').setAttribute('aria-expanded', 'false')
    // accordion__children.map(child => child.setAttribute('hidden',""))
}

export {closeAllAccordionItems, expandAccordionItemById, closeAccordionItemsById}