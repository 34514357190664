import React from 'react'
import Logo from "../../../../images/logo.inline.svg"
import { Link } from 'gatsby'
import { Styling } from './styled.js'
import {BsArrowLeft} from 'react-icons/bs'

export default function CoursePlayerHeader() {
    return (
        <Styling>
            <Link className="backToDash" to='/course-dashboard/' >
                <BsArrowLeft className="arrow"/> <span className="backtotext">Back to dashboard</span>
            </Link>
            <Link to='/' >
                <Logo className="logo" />
            </Link>
        </Styling>
    )
}
