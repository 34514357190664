import React  from 'react'
import { AccordionItemPanel } from 'react-accessible-accordion';
import Download from '../../../../images/download.inline.svg'
import Play from '../../../../images/play_video.inline.svg'
import CheckboxUnchecked from '../../../../images/checkbox_unchecked.inline.svg'
import CheckboxChecked from '../../../../images/checkbox_checked.inline.svg'
import { expandAccordionItemById, closeAllAccordionItems } from '../../../../utils/openCloseAccordion';
export default function CurriculumLesson({
    lesson, 
    index, 
    chapter,
    setCurrentLesson,
    completed,
    currentLesson,
    chapterIndex,
    setCurrentChapterIndex,
    setCurrentLessonIndex,
    courseProgress,
    isComplete
}) {
    const handleClick = (e) => {
        setCurrentLessonIndex(index)
        setCurrentChapterIndex(chapterIndex)
        closeAllAccordionItems()
        expandAccordionItemById(chapterIndex)
    }

    
    return (
        <div>
           <AccordionItemPanel 
           onClick={(e) => handleClick(e)}
           className={`lesson ${currentLesson.lessonTitle === lesson.lessonTitle ? 'active' : ""}`}
           data-lesson={lesson}
           > 

                {lesson.lessonVideoDuration ? 
                    <div>
                        <div className="lesson_title">{lesson.lessonTitle}</div>
                        <div className="duration">
                            <Play className="playIcon"/> {lesson.lessonVideoDuration}mins
                        </div>
                    </div> 
                : ""}
                {lesson.lessonFile ? 
                    <a href={lesson?.lessonFile?.localFile?.publicURL} download>
                        <div className="lesson_title">{lesson.lessonTitle}</div>
                        <div className="download" >
                            <Download className="downloadIcon"/> Download
                        </div>
                    </a>
                : ""}
             
                {
                    completed.includes(lesson.lessonTitle) ? <CheckboxChecked className="checkbox" /> : <CheckboxUnchecked className="checkbox" /> 
                }
            </AccordionItemPanel>
        </div>
    )
}
