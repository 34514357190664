import React from 'react'
import { Styling } from './styled.js'
import InstructorInfo from './InstructorInfo'

export default function COMPONENT({courseTitle, currentLesson, instructors}) {
    return (
        <Styling className="info">
            <h1>{courseTitle}: <br />{currentLesson.lessonTitle}</h1>
            <div className="instructors">
                {instructors.map((instructor, index) => {
                   return <InstructorInfo instructor={instructor} />
                })}
            </div>
        </Styling>  
    )
}
