
import styled from 'styled-components'

const Styling = styled.div`
${({theme}) => theme.telegraf('regular')}  
overflow-y: scroll;
overflow-x:hidden;
position: relative;

    .curriculum_content_buttons{
    padding:0 4rem;
    display: inline-flex;
    width: 100%;
    position: relative;
    z-index: 10;
    background:var(--grey_light);

        @media only screen and (min-width: 1024px) {
        position: fixed;
        }

        .active{
            border-bottom:2px solid rgba(0,0,0,1) !important;
            ${({theme}) => theme.telegraf('bold')}  
        }

        button{
        border:0;
        padding:2rem 0;
        border-bottom:2px solid rgba(0,0,0,0);
        ${({theme}) => theme.easing('0.5')}  

            &:first-child{
            margin-right: 2rem;
            }

            &:hover{
                border-bottom:2px solid rgba(0,0,0,0.2);
            }

        }


    }

    .downloadableContent{
    position: absolute;
    top:58px;
    left:0;
    transform:translateX(100%);

        .downloadableContentItem{
        width: 100%;
        display: inline-flex;
        padding:2rem 4rem;
        justify-content: space-between;
        border-bottom:1px solid var(--grey_mid);
        ${({theme}) => theme.easing('0.5')}  
        cursor: pointer;
        color:inherit;
        text-decoration:none;

            &.downloadableContentItem-0{
            border-top:1px solid var(--grey_mid);
            ${({theme}) => theme.easing('0.5')} 
            }

            .downloadIcon{
                path{
                ${({theme}) => theme.easing('0.5')}  
                }
            }
            &:hover{
            background:var(--black);
            color:var(--white);
            border-bottom:1px solid var(--black);

                &.chapter-0{
                border-top:1px solid var(--black);
                }

                .downloadIcon path{
                fill:var(--white);
                }

            }
        }

    }

    .chapter{
    width: 100%;
    display: inline-flex;
    padding:2rem 4rem;
    justify-content: space-between;
    border-bottom:1px solid var(--grey_mid);
    ${({theme}) => theme.easing('0.5')}  
    cursor: pointer;

        &.chapter-0{
        border-top:1px solid var(--grey_mid);
        ${({theme}) => theme.easing('0.5')} 
    

            @media only screen and (min-width: 1024px) {
            margin-top:58px;
            }
        }
        .number{
            ${({theme}) => theme.telegraf('bold')}      
            font-size: 1.4rem;
            line-height: 2rem;
        }
        .chapter_title{
            ${({theme}) => theme.telegraf('bold')}      
            font-size: 1.4rem;
            line-height: 2rem;
            width: calc(100% - 7rem);        
            padding-left:0.8rem;
        }

        .chapter-right{
        display: flex;
        align-items:center;

            .progress{
            margin-right: 1rem;
            font-size:0.9em;
            }

            .chevron{
            transform: rotate(-180deg);
            ${({theme}) => theme.easing('0.5')}  

                path{
                ${({theme}) => theme.easing('0.5')}  
                }
            }

        }

        &[aria-expanded="true"]{
            .chevron{
            transform: rotate(0);
            }
        }
        

        &:hover{
        background:var(--black);
        color:var(--white);
        border-bottom:1px solid var(--black);

            &.chapter-0{
            border-top:1px solid var(--black);
            }

            .chevron path{
            stroke:var(--white);
            }

        }
    }

    .lesson{
    width: 100%;
    padding:2rem 4rem;
    justify-content: space-between;
    border-bottom:1px solid var(--grey_mid);
    ${({theme}) => theme.easing('0.5')}  
    cursor: pointer;
    position: relative;

        .lesson_title{
        margin-bottom: 1rem;
        width: calc(100% - 4rem);
        }

        a{
        color:inherit;
        text-decoration:none;
        }

        .duration,
        .download{
        display: flex;
        align-items:center;
        font-size:0.9em;

            .playIcon,
            .downloadIcon{
            margin-top:-0.2rem;
            margin-right: 0.75rem;

                path{
                ${({theme}) => theme.easing('0.5')}  
                }

            }
        }

        .checkbox{
        position:absolute;
        right:4rem;
        top:50%;
        transform: translateY(-50%);
            rect{
            ${({theme}) => theme.easing('0.5')}  
            }
        }

        &.active{
        background:var(--grey_mid);
        /* color:var(--white); */
        }

        &:hover{
        background:var(--black);
        color:var(--white);

            .checkbox rect{
                stroke:var(--white);
            }

            .playIcon path,
            .downloadIcon path{
            fill:var(--white);
            }

        }
    }

`

export { Styling }