
import styled from 'styled-components'
const Styling = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
background:var(--white);

    #loading{
    width:100%;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    background:var(--white);
    color:var(--black);
    font-size:5rem;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding-bottom: 0 !important;
    text-transform:uppercase;
    }

    .player_upper{
    width: 100%;
    display: flex;
    position: relative;
    height:100%;
    overflow: hidden;

        @media only screen and (max-width: 1024px) {
        flex-wrap: wrap;
        }

        .left{
        width: 70vw;
        width:calc(100% - 43rem);

            @media only screen and (max-width: 1024px) {
            width: 100%;
            }

            .player{
            position: relative; 
            padding-bottom: 56.25%; 
            height: 0; 
            overflow: hidden; 
            width: 100%;
            }

            .info{
            height:auto;
            display: none;
            padding:4rem;

                @media only screen and (min-width: 1024px) {
                    display: block;
                }

                h1{
                ${({theme}) => theme.fontSize('18')}  
                text-transform: uppercase;
                    @media only screen and (min-width: 1024px) {
                    ${({theme}) => theme.fontSize('30')}  
                    margin-bottom:4rem;
                    }
                }

                .instructors{
                display: none;

                    @media only screen and (min-width: 1024px) {
                    display: block;
                    }
                }

            }
    
            embed,
            object,
            iframe{
            position: absolute; 
            top: 0; 
            left: 0; 
            width: 100%; 
            height: 100%;
            }

            /* .info{
            padding-bottom: 0%;
            } */
    
        }
    
        .right{
        width:43rem;
        position: fixed;
        right:0;
            
            @media only screen and (max-width: 1024px) {
            width: 100%;
            position: relative;
            }
    
            .curriculum{
            width: 100%;
            background:var(--grey_light);
            }

            .downloadableContent{
            width: 100%;
            }

        }
    }



`

export { Styling }