import React from 'react'
import { Styling } from './instructorInfo.styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';

function InstructorInfo({instructor}) {

    return (
        <Styling>
            <GatsbyImage image={getImage(instructor?.instructor?.instructor?.image?.localFile)} className="profile" alt={'test'} />
            <div className="instructor_info">
                <div className="name">{instructor.instructor.title}</div>
                <div className="bio">{instructor.instructor.instructor.biography}</div>
            </div>
        </Styling>
    )
}

export default InstructorInfo
