
import styled from 'styled-components'

const Styling = styled.header`
display: flex;
justify-content: center;
align-items: center;
font-size:clamp(1.5rem, 1vw, 4rem);
position: fixed;
top:0;
left:0;
width: 100%;
z-index: 10000;
padding:clamp(2rem, 1vw, 4rem)  clamp(2rem, 4vw, 8rem);
background:var(--white);

    .backToDash{
    position: absolute;
    left:2rem;
    text-decoration: none;
    color:var(--black);
    font-size:clamp(1.5rem, 0.6vw, 2rem);
    ${({theme}) => theme.tomato('bold')}  
    text-transform:uppercase;
    display: flex;
    align-items:center;
    
        
        @media only screen and (min-width:1024px) {
        left:4rem;
        }

        .arrow{
        margin-right: 1rem;
        font-size: 3rem;
        }

        .backtotext{
        display: none;

            @media only screen and (min-width: 1024px) {
            display: block;
            }
        }
    
    }
    
    .logo{
    width:clamp(10rem, 9.5vw, 18rem);
        path{
        fill:var(--black);
        }
    }


`

export { Styling }