import React from 'react'

export default function CurriculumContentButtons({downloadCount, handleClick, activeButton}) {
    return (
        <div className="curriculum_content_buttons">
            <button id="content" className={activeButton === `content` ? `content active` : `content`} onClick={(e) => handleClick(e)}>Course Content</button>
            {downloadCount > 0 ? <button id="downloads" className={activeButton === `downloads` ? `downloads active` : `downloads`} onClick={(e) => handleClick(e)}>Downloads ({downloadCount})</button> : "" }
        </div>  
    )
}
